import { css } from '@emotion/react';
import styled from '@emotion/styled';

export default styled.div`
  .swiper-slide {
    width: ${({ itemWidth }) => `${itemWidth}px`};
  }

  .thumbs-gallery {
    margin-right: calc(0px - var(--pr));
    margin-left: calc(0px - var(--pl));

    &.up {
      picture {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    ${({ theme }) => theme.media.laptop} {
      margin-right: 0;
      margin-left: 0;

      &.up {
        border-radius: 4px;
      }
    }

    .swiper-slide {
      height: auto;
    }

    img,
    video {
      object-fit: cover;
      width: 100%;
      height: 100%;
      aspect-ratio: 4 / 3;
    }

    .swiper-slide-thumb-active {
      background-color: black;

      img,
      video {
        opacity: 0.7;
      }
    }
  }

  .swiper {
    --swiper-theme-color: ${({ theme }) => theme.colors.bahamaBlue};
    --swiper-pagination-bullet-inactive-color: ${({ theme }) =>
      theme.colors.quartz};
    --swiper-pagination-bullet-inactive-opacity: 1;
    --swiper-pagination-bullet-size: 12px;
    --swiper-pagination-bullet-horizontal-gap: 5px;
    --swiper-pagination-color: ${({ theme }) => theme.colors.bahamaBlue};

    position: relative;
    margin-bottom: 4px;
    &:not(.thumbs-gallery) {
      ${({ fullWidth }) =>
        fullWidth &&
        css`
          margin-right: calc(0px - var(--pr));
          margin-left: calc(0px - var(--pl));
        `}
      ${({ pagination }) =>
        pagination &&
        css`
          padding-bottom: 32px;
        `}
    }

    .swiper-slide {
      height: auto;
      & > * {
        height: 100%;
      }
    }
    & > .swiper-pagination-bullets {
      bottom: 0;
      line-height: var(--swiper-pagination-bullet-size);
    }
    & .swiper-zoom-container {
      cursor: zoom-in;
    }

    & .swiper-slide-zoomed .swiper-zoom-container {
      cursor: move;
    }
  }
`;
