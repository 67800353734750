import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import StyledCarousel from './Carousel.style';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, FreeMode, Navigation, Thumbs,  Zoom, Keyboard } from 'swiper/modules';

import 'swiper/css'; // core Swiper
import 'swiper/css/pagination'; // Pagination module
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import 'swiper/css/thumbs';

const Carousel = ({
  children,
  itemWidth,
  fullWidth = true,
  slidesPerView = 'auto',
  swiperType = 'carousel',
  keyboardEnabled = false,
  spaceBetween = 15,
  pagination = true,
  slidesOffsetBefore = 15,
  slidesOffsetAfter = 15,
  onSwiper,
  ...p
}) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const swiperRef = useRef(null);
  const options = {
    carousel: {
      slidesOffsetBefore,
      slidesOffsetAfter,
      spaceBetween,
      slidesPerView,
      ...(pagination
        ? {
            modules: [Pagination],
            pagination: { clickable: true },
          }
        : {}),
    },
    thumbsGallery: {
      style: {
        '--swiper-navigation-color': '#fff',
        '--swiper-pagination-color': '#fff',
      },
      thumbs: { swiper: thumbsSwiper },
      navigation: true,
      modules: [FreeMode, Navigation, Thumbs, Zoom, Keyboard],
      initialSlide: 0,
      className: 'thumbs-gallery up',
      loop: true,
    },
  };

  const thumbsOpt = {
    spaceBetween: 4,
    slidesPerView: 5,
    freeMode: true,
    watchSlidesProgress: true,
    modules: [FreeMode, Navigation, Thumbs],
    className: 'thumbs-gallery',
    initialSlide: 0,
    loop: true,
  };

  useEffect(() => {
    const keyUpHandler = (event) => {
      if (!swiperRef.current) return;
      if (event.key == 'ArrowLeft') swiperRef.current.slidePrev();
      if (event.key == 'ArrowRight') swiperRef.current.slideNext();
    };
    if (keyboardEnabled) window.addEventListener('keyup', keyUpHandler);
    else window.removeEventListener('keyup', keyUpHandler);
    return () => window.removeEventListener('keyup', keyUpHandler);
  }, [keyboardEnabled]);

  return (
    <StyledCarousel
      itemWidth={itemWidth}
      pagination={pagination}
      fullWidth={fullWidth}
    >
      <Swiper
        {...options[swiperType]}
        zoom={swiperType === 'thumbsGallery'}
        onSwiper={(s) => {
          setTimeout(() => {
            s.slideToLoop(0, 0);
          }, 50);
          swiperRef.current = s;
          onSwiper?.(s);
        }}
        {...p}
      >
        {children?.map((c, ix) => (
          <SwiperSlide key={ix}>
            {swiperType === 'thumbsGallery' ? (
              <div className="swiper-zoom-container">
                {typeof c === 'function' ? c({ type: 'main' }) : c}
              </div>
            ) : (
              <>{typeof c === 'function' ? c({ type: 'main' }) : c}</>
            )}
          </SwiperSlide>
        ))}
      </Swiper>

      {swiperType === 'thumbsGallery' && (
        <Swiper
          onSwiper={(s) => {
            setTimeout(() => {
              s.slideToLoop(0, 0);
            }, 50);
            setThumbsSwiper(s);
          }}
          {...thumbsOpt}
          {...p}
        >
          {children?.map((c, ix) => (
            <SwiperSlide key={ix}>
              {typeof c === 'function' ? c({ type: 'thumbnail' }) : c}
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </StyledCarousel>
  );
};
export default Carousel;
